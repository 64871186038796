import type { MessagesIntlId } from '@/components/formatted-message'

export const FormErrorsMessages: Record<string, MessagesIntlId> = {
  Required: 'global.formErrors.required',
  DuplicatedPhones: 'global.formErrors.duplicatedPhones',
  InvalidEmail: 'global.formErrors.invalidEmail',
  InvalidPhone: 'global.formErrors.invalidPhone',
  Max150Characters: 'global.formErrors.max150Characters',
  Max20Characters: 'global.formErrors.max20Characters',
  InvalidServiceType: 'global.formErrors.invalidService',
  ShouldContainOnlyLetters: 'global.formErrors.shouldContainOnlyLetters',
  Invalid: 'global.formErrors.invalid',
  InvalidCard: 'global.formErrors.invalidCard',
  InvalidAccount: 'global.formErrors.invalidAccount',
  InvalidPackageNumber: 'global.formErrors.invalidPackageNumber',
  InvalidTransactionNumber: 'global.formErrors.invalidTransactionNumber',
  InvalidDateOrFirstName: 'global.formErrors.invalidDateOrFirstName',
  MaxTwoDigits: 'global.formErrors.maxTwoDigits',
  InvalidNumber: 'global.formErrors.invalidNumber',
  MinBalance: 'global.formErrors.minBalance',
  MaxTransferError: 'checkout.maxTransfer',
  MinTransferError: 'checkout.minTransfer',
  InvalidOtpCode: 'global.formErrors.invalidOtpCode',
  InvalidDeviceInfo: 'global.formErrors.invalidDeviceInfo',
  InvalidCVV: 'global.formErrors.invalidCVV',
  InvalidDeliveryPoint: 'global.formErrors.invalidDeliveryPoint',
  PhoneEqualHomePhone: 'global.formErrors.phoneEqualHomePhone',
  MaxLength255: 'global.formErrors.maxLength255',
  MaxBalance: 'global.formErrors.maxBalance',
  SameUserPhone: 'global.formErrors.sameUserPhone',
  InvalidCardHolder: 'global.formErrors.invalidCardHolder',
  RequiredDate: 'global.formErrors.requiredDate',
  RequiredFromDate: 'global.formErrors.requiredFromDate',
  RequiredToDate: 'global.formErrors.requiredToDate',
  InvalidRangeDate: 'global.formErrors.invalidRangeDate',
  AdultGtInfants: 'flights.formErrors.adultGtInfants',
  MinPassengers: 'flights.formErrors.minPassengers',
  MaxPassengers: 'flights.formErrors.maxPassengers',
  SameDocumentNumber: 'global.formErrors.sameDocumentNumber',
  DuplicatedDocumentNumber: 'global.formErrors.duplicatedDocumentNumber',
  MaxLength80: 'global.formErrors.maxLength80',
}
