import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import type { AddPaymentMethodCard } from './payment-methods.types'

export type AddPaymentMethodStates = {
  showAddCard: boolean
  card: AddPaymentMethodCard
}

export type AddPaymentMethodActions = {
  setShowAddCard: (showAddCard: boolean) => void
  setCard: (card: AddPaymentMethodStates['card']) => void
}

export const addPaymentMethodDefaultValues = {
  showAddCard: false,
  card: null,
}

export const useAddPaymentMethodStore = create<
  AddPaymentMethodStates & AddPaymentMethodActions
>()(
  persist(
    (set) => ({
      ...addPaymentMethodDefaultValues,
      setShowAddCard: (showAddCard) =>
        set({
          showAddCard,
        }),
      setCard: (card) =>
        set({
          card,
        }),
    }),
    {
      name: 'add-payment-method',
    }
  )
)
