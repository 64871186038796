'use client'

import { useEffect } from 'react'
import { Button } from 'ui'
import { AbortErrorStatusMessage } from 'libs'
import { ButtonLink } from '@/components/button-link'
import { FormattedMessage } from '@/components/formatted-message'
import { WebRoutes, captureException } from '@/shared'

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  useEffect(() => {
    captureException(error)
  }, [error])

  return (
    <div className="container items-center justify-center p-10 min-h-[40dvh]">
      <h2 className="flex flex-col gap-2 mb-8 text-center">
        {error.message === AbortErrorStatusMessage ? (
          <>
            <FormattedMessage id="global.errorConnection.title" />
            <span className="inline-block text-base">
              <FormattedMessage id="global.errorConnection.description" />
            </span>
          </>
        ) : (
          <FormattedMessage id="global.wentWrong" />
        )}
      </h2>
      <div className="flex flex-col items-center justify-center gap-2 md:flex-row">
        <ButtonLink
          className="w-fit"
          href={WebRoutes.Home}
          onClick={reset}
          variant="ghost"
        >
          <FormattedMessage id="global.errorButtonGoToHome" />
        </ButtonLink>
        <Button className="w-fit" onClick={reset}>
          <FormattedMessage id="global.tryAgain" />
        </Button>
      </div>
    </div>
  )
}
