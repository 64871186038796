import {
  addCubanPrefix,
  clearFormattedPhone,
  addUsaPrefix,
  isValidCubanPhoneNumber,
  isValidPhoneNumber,
} from 'libs'
import { z } from 'zod'
import { FormErrorsMessages } from '../constants'

const commonPhoneCubanSchema = z
  .string({ required_error: FormErrorsMessages.Required })
  .trim()
  .min(1, { message: FormErrorsMessages.Required })
  .transform((value) => {
    const phone = clearFormattedPhone(value)

    return addCubanPrefix(phone)
  })

export const phoneCubanSchema = commonPhoneCubanSchema.refine(
  (value) =>
    value.length === 11 && isValidCubanPhoneNumber({ phoneNumber: value }),
  {
    message: FormErrorsMessages.InvalidPhone,
  }
)

export const mobileAndHomePhoneCubanSchema = commonPhoneCubanSchema.refine(
  (value) =>
    value.length === 11 &&
    isValidCubanPhoneNumber({ phoneNumber: value, allowHomePhone: true }),
  {
    message: FormErrorsMessages.InvalidPhone,
  }
)

export const phoneUsSchema = z
  .string({
    required_error: FormErrorsMessages.Required,
  })
  .trim()
  .min(1, { message: FormErrorsMessages.Required })
  .transform((value) => {
    const phone = clearFormattedPhone(value)
    return addUsaPrefix(phone)
  })
  .refine((value) => isValidPhoneNumber(value), {
    message: FormErrorsMessages.InvalidPhone,
  })

export const genericPhoneSchema = z
  .string({
    required_error: FormErrorsMessages.Required,
  })
  .trim()
  .min(1, { message: FormErrorsMessages.Required })
  .transform((value) => {
    const phone = clearFormattedPhone(value)
    return addUsaPrefix(phone)
  })
  .refine((value) => isValidPhoneNumber(value), {
    message: FormErrorsMessages.InvalidPhone,
  })
