'use client'

import { getCookies } from 'cookies-next'
import { forwardRef } from 'react'
import type {
  ComponentPropsWithRef,
  ForwardedRef,
  MouseEventHandler,
} from 'react'
import type { Sizes, Variants } from 'ui'
import { ButtonStyles } from 'ui'
import { cn } from 'utils-tailwindcss'
import { usePathname } from 'next/navigation'
import { WebRoutes, protectedRoutes } from '@/shared'
import { useAddPaymentMethodStore } from '@/services/payment-methods/add-payment-method.store'
import { Link } from '@/navigation'

export type ButtonLinkProps = ComponentPropsWithRef<typeof Link> &
  Variants & {
    size?: Sizes
  }

function ButtonLinkWithRef(
  {
    href,
    children,
    variant,
    className,
    prefetch = true,
    onClick,
    size = 'default',
    ...restProps
  }: ButtonLinkProps,
  ref: ForwardedRef<HTMLAnchorElement>
) {
  const pathname = usePathname()
  const { setCard, setShowAddCard } = useAddPaymentMethodStore()
  const { access_token: accessToken, refresh_token: refreshToken } =
    getCookies()
  const isNotAuthenticated = !accessToken && !refreshToken
  const isProtectedRoute = protectedRoutes.includes(
    href as (typeof protectedRoutes)[number]
  )
  const isLoginPage = pathname.includes(WebRoutes.Login)
  const isSameRoute = pathname === href
  const isDisabled =
    (isProtectedRoute && isNotAuthenticated && isLoginPage) || isSameRoute

  const disableTopLoader: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault()
    e.nativeEvent.stopImmediatePropagation()
  }

  const handleClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (isDisabled) {
      disableTopLoader(e)
    }
    onClick?.(e)
    setCard(null)
    setShowAddCard(false)
  }

  return (
    <Link
      {...restProps}
      className={cn(
        ButtonStyles({
          variant,
          size,
        }),
        className
      )}
      href={href}
      onClick={handleClick}
      prefetch={prefetch}
      ref={ref}
    >
      {children}
    </Link>
  )
}

export const ButtonLink = forwardRef(ButtonLinkWithRef)
