export enum PaymentStatusEnum {
  PENDING = 10,
  CANCELED = 100,
  COMPLETED = 1,
  DECLINED = 2,
  REJECTED = 3,
  FULL_REFUND = 4,
  PARTIAL_REFUND = 5,
  FULL_REFUND_TO_BALANCE = 6,
  GATEWAY_ERROR = 500,
  INITIATED_3DS = 11,
  NONE = -1,
}
