import { usePathname } from '@/navigation'

export const useRemoveSearchParams = () => {
  const pathname = usePathname()

  const removeSearchParams = () => {
    window.history.replaceState(null, '', pathname)
  }

  return { removeSearchParams }
}
