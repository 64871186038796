import { z } from 'zod'
import { FormErrorsMessages } from '../constants'

export const locationSchema = z
  .object(
    {
      key: z
        .string({
          required_error: FormErrorsMessages.Required,
        })
        .min(1, { message: FormErrorsMessages.Required }),
      value: z
        .string({
          required_error: FormErrorsMessages.Required,
        })
        .min(1, { message: FormErrorsMessages.Required }),
    },
    { required_error: FormErrorsMessages.Required }
  )
  .refine((value) => !!value.key && !!value.value, {
    message: FormErrorsMessages.Required,
  })

export const addressSchema = z.object({
  country: locationSchema,
  state: locationSchema,
  city: locationSchema,
  postalCode: z
    .string({ required_error: FormErrorsMessages.Required })
    .trim()
    .min(1, { message: FormErrorsMessages.Required })
    .refine((value) => /^[0-9]{5}(?:-[0-9]{4})?$/.test(value), {
      message: FormErrorsMessages.Invalid,
    }),
  address: z
    .string({
      required_error: FormErrorsMessages.Required,
    })
    .trim()
    .min(1, { message: FormErrorsMessages.Required }),
})
