import { getIETFEmailSchema, requiredDate } from 'libs'
import { z } from 'zod'
import { FormErrorsMessages } from '../constants'
import { phoneUsSchema } from './phone.schema'

export const userSchema = z.object({
  firstName: z
    .string({
      required_error: FormErrorsMessages.Required,
    })
    .trim()
    .min(1, { message: FormErrorsMessages.Required })
    .refine((value) => /^[^\d]{1,80}$/.test(value), {
      message: FormErrorsMessages.MaxLength80,
    }),
  secondName: z
    .string()
    .trim()
    .refine((value) => value === '' || /^[^\d]{1,80}$/.test(value), {
      message: FormErrorsMessages.MaxLength80,
    })
    .optional(),
  lastName: z
    .string({
      required_error: FormErrorsMessages.Required,
    })
    .trim()
    .min(1, { message: FormErrorsMessages.Required })
    .refine((value) => /^[^\d]{1,80}$/.test(value), {
      message: FormErrorsMessages.MaxLength80,
    }),
  secondSurname: z
    .string({
      required_error: FormErrorsMessages.Required,
    })
    .trim()
    .refine((value) => value === '' || /^[^\d]{1,80}$/.test(value), {
      message: FormErrorsMessages.MaxLength80,
    }),
  dateOfBirth: requiredDate({
    requiredMessage: FormErrorsMessages.Required,
  }),
  gender: z
    .string({
      required_error: FormErrorsMessages.Required,
    })
    .min(1, { message: FormErrorsMessages.Required }),
  consent: z.boolean().default(true),
  phone: phoneUsSchema,
  email: z
    .string({ required_error: FormErrorsMessages.Required })
    .trim()
    .min(1, { message: FormErrorsMessages.Required })
    .email({
      message: FormErrorsMessages.InvalidEmail,
    })
    .pipe(
      getIETFEmailSchema({
        invalidMessage: FormErrorsMessages.InvalidEmail,
        requiredMessage: FormErrorsMessages.Required,
      })
    ),
})
