import { z } from 'zod'
import { FormErrorsMessages } from '../constants'
import { validateNumberWithTwoDecimal } from '../utils'

export const decimalSchema = z
  .string({
    required_error: FormErrorsMessages.Required,
    coerce: true,
    invalid_type_error: FormErrorsMessages.Required,
  })
  .min(1, FormErrorsMessages.Required)
  .refine(
    (value) => {
      if (value.split('.')[1]) return value.split('.')[1].length <= 2
      return true
    },
    {
      message: FormErrorsMessages.MaxTwoDigits,
    }
  )
  .refine((value) => validateNumberWithTwoDecimal(value), {
    message: FormErrorsMessages.InvalidNumber,
  })
