import type { UrlObject } from 'node:url'
import { usePathname } from '@/navigation'

export const useActiveLink = ({
  href,
  matchUrls,
}: {
  href: string | UrlObject
  matchUrls?: string[]
}) => {
  const pathname = usePathname()

  const isActive =
    href === pathname || matchUrls?.some((matchUrl) => matchUrl === pathname)

  return isActive
}
