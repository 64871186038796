import { create } from 'zustand'

type LoaderProps = {
  isLoading: boolean
}

type LoaderActions = {
  onLoading: (isLoading: boolean) => void
}

export const useLoader = create<LoaderProps & LoaderActions>((set) => ({
  isLoading: false,
  onLoading: (isLoading) => set({ isLoading }),
}))
