import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import type { SERVICE_TYPE } from 'libs'
import type { ServiceConfigData } from '@/services/configuration'
import type { HistoryLink, WebRoutes } from '../routes'

type ServiceConfig = {
  message: string | null
  serviceEnabled: boolean
  payWithBalance: boolean
  payWithReward: boolean
  navigateTo?: WebRoutes
  historyLink?: HistoryLink
}

type ServiceConfigProps = {
  setServiceType: (serviceType: SERVICE_TYPE | null) => void
  setServiceConfig: (serviceConfig: ServiceConfig) => void
  setServiceSettings: (
    settings: Partial<Record<string, ServiceConfigData['settings']>>
  ) => void
  serviceType?: SERVICE_TYPE | null
  // TODO: Fix type, use a key the SERVICE_TYPE
  services?: Record<string, ServiceConfig>
  settings?: Partial<Record<string, ServiceConfigData['settings']>>
}

export const useServiceConfigStore = create<ServiceConfigProps>()(
  persist(
    (set) => ({
      setServiceSettings: (settings) =>
        set((state) => ({
          settings: {
            ...state.settings,
            ...settings,
          },
        })),
      setServiceConfig: (serviceConfig) =>
        set((state) => {
          if (!state.serviceType) return state

          return {
            services: {
              ...state.services,
              [state.serviceType as string]: serviceConfig,
            },
          }
        }),
      setServiceType: (serviceType) => set({ serviceType }),
    }),
    {
      name: 'serviceConfig',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)

export const useOpenServiceConfigDialog = create<{
  onOpenDialog: (open: boolean) => void
  openDialog?: boolean
}>((set) => ({
  onOpenDialog: (open: boolean) => set({ openDialog: open }),
}))
